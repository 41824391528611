@import "src/flux-styles/variables";

.form {
  &__subheading {
    // to overwrite admin-palette styles
    *[class^="apl-"] & {
      font-size: 1.05rem;
    }
  }

  &__delete-rate {
    background: none;
    border: none;
    cursor: pointer;
    margin-top: 6px;
    
    .apl-form-layout-v1 & {
      padding: 0;
    }
  }
}

.apl-form-layout-v1 {
  .apl-field__label {
    font-weight: 600;
  }

  [type="date"] {
    font-family: $sans-serif;
  }
}
