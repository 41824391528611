@import 'src/flux-styles/variables';

.context-menu-button {
  appearance: none;
  background-color: var(--base-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.1875rem;
  line-height: 1;
  margin-left: 10px;
  padding: 0 2px;

  &:hover {
    cursor: pointer;
  }

  &__icon {
    margin-top: 2px;
  }
}

.context-menu {
  background: var(--base-color);
  border: 1px solid var(--secondary-bg);
  border-radius: 5px;
  margin: 0;
  left: 0;
  position: absolute;
  top: calc(100% - 5px);
  z-index: 999;

  &__block {
    border-bottom: 1px solid var(--secondary-bg);
    padding: 10px 0;

    &--small {
      padding-bottom: 7px;
      padding-top: 7px;
    }
  }

  &__button {
    background: var(--base-color);
    border: none;
    color: var(--content-color);
    display: block;
    padding: 6px $small-spacing;
    text-align: left;
    width: 100%;

    &:hover {
      background: var(--primary-color);
      color: var(--base-color);
      cursor: pointer;
    }

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.5;

      &:hover {
        background: var(--base-color);
        color: var(--content-color);
      }
    }
  }
}