// # Components / Card
//
// Cards are the essential building block of pages. They can contain many types
// of content, but should only contain a few of these each, like in the Examples.
// [<span class="badge is-legacy">v0 legacy</span>](#architecture_versioning)
//
// ## General structure
//
// Cards will usually be at `<h2>` level, just below the top level header. The
// first card on a page is usually described by the top level header and doesn't
// need a title of its own, but any cards providing supplementary information
// or actions need titles which explain their content.
//
// ```example
// <section class="card">
//   <h2>User details</h2>
//   <dl class="form-fields is-grid">
//     <dt>Username</dt><dd>Jon Snow</dd>
//     <dt>Password</dt><dd>Don't know</dd>
//   </dl>
// </section>
// ```
.card {
  $card-padding: $medium-spacing;

  margin-bottom: $medium-spacing;
  padding: $card-padding;
  border-radius: calc($corner-radius / 2);
  background: var(--base-color);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

  // ## Multi-part cards
  //
  // If a large amount of content (such as a long form) cannot be broken into
  // multiple cards, use multiple `.card-block` containers to break up the
  // content within a card.
  //
  // ```example
  // <section class="card">
  //   <div class="card-block">Meter details form</div>
  //   <div class="card-block">Register 1 details form</div>
  //   <div class="card-block">Register 2 details form</div>
  // </section>
  // ```
  .card-block {
    padding: $card-padding;
    margin-left: -$card-padding;
    margin-right: -$card-padding;
    margin-bottom: $medium-spacing;
    border-top: 1px solid var(--secondary-bg);
    border-bottom: 1px solid var(--secondary-bg);

    &:first-child {
      margin-top: -$card-padding;
      border-top: none;
    }

    &:last-child {
      margin-bottom: -$card-padding;
      border-bottom: none;
    }

    &.is-secondary {
      background-color: var(--tint-bg);
    }

    + .card-block {
      border-top: none;
      margin-top: -$medium-spacing;
    }

    // ## Sub card blocks
    //
    //  If the card contain multiple repeat sub-sections card block
    //
    //
    .sub-card-block {
      padding: $card-padding;
      margin-left: -$card-padding;
      margin-right: -$card-padding;
      margin-bottom: $medium-spacing;
      border-bottom: 1px solid var(--secondary-bg);
    }
  }

  // ## Layouts within cards
  //
  // Row layouts can still be used in cards, for example to place different
  // kinds of table filters next to each other.
  //
  // ```example
  // <section class="card">
  //   <div class="row">
  //     <div class="action-list">
  //       <a href="#" class="is-active">Active</a>
  //       <a href="#">Inactive</a>
  //     </div>
  //
  //     <select class="input">
  //       <option>None</option>
  //     </select>
  //   </div>
  //
  //   <p>Table goes here</p>
  // </section>
  // ```
  > .row {
    margin-bottom: $medium-spacing;

    > *:not(:last-child) {
      margin-right: $medium-spacing;
    }
  }

  // ## Muted cards
  //
  // Occasionally a page will have a sidebar that isn't part of the main content and
  // should fade into the background unless it's explicitly needed. Use `.is-muted` for
  // this sort of thing.
  //
  // ```example
  // <section class="card is-muted">
  //   Sidebar content
  // </section>
  // ```
  &.is-muted {
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
  }
}
