.pagination {
  background-color: var(--base-color);

  &__link {
    &:disabled {
      color: #DBDBDB;
    }
  }

  &__button {
    appearance: none;
    background-color: var(--base-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-right: none;
    color: var(--primary-color);
    cursor: pointer;
    font-weight: 600;
    padding: 5px 9px;

    &:hover {
      opacity: 0.8;
    }

    &:disabled {
      color: #585858;
      cursor: not-allowed;
    }

    &--first {
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
    }

    &--last {
      border-bottom-right-radius: 3px;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      border-top-right-radius: 3px;
    }
  }
}