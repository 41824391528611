@import "svg";

$themes: (light, dark);
$default-theme: light;

$all-theme-colors: (
  light: (),
  dark: (),
);

@mixin themed-colors($theme-colors) {
  @if map-keys($theme-colors) != $themes {
    @error "You must provide colours for all the themes the app supports: #{$themes}";
  }

  $default-theme-colors: map-get($theme-colors, $default-theme);

  :root {
    @each $name, $color in $default-theme-colors {
      #{"--" + $name}: $color;
    }
  }

  @each $theme-name, $colors in $theme-colors {
    @if map-keys($default-theme-colors) !=
      map-keys(map-merge($default-theme-colors, $colors))
    {
      @error "The colours you specify in each theme must be a subset of the default theme";
    }

    $all-theme-colors: map-merge(
      $all-theme-colors,
      (
        $theme-name:
          map-merge(map-get($all-theme-colors, $theme-name), $colors),
      )
    ) !global;

    .theme-#{$theme-name} {
      @each $name, $color in $colors {
        #{"--" + $name}: $color;
      }
    }
  }
}

@mixin themed-svg($color, $viewbox, $svg) {
  @each $theme-name, $colors in $all-theme-colors {
    $theme-color: map-get($colors, $color);
    .theme-#{$theme-name} & {
      background-image: svg-url(
        "<svg viewBox='#{$viewbox}'><g fill='#{$theme-color}'>#{$svg}</g></svg>"
      );
    }
  }

  $theme-color: map-get(map-get($all-theme-colors, $default-theme), $color);
  background-image: svg-url(
    "<svg viewBox='#{$viewbox}'><g fill='#{$theme-color}'>#{$svg}</g></svg>"
  );
}
