$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1400px;

.half_lg {
  width: 100%;
  @media (min-width: $screen-lg) {
    width: 50%;
  }
}

.half_xl {
  width: 100%;
  @media (min-width: $screen-xl) {
    width: 50%;
  }
}

.third_lg {
  width: 100%;
  @media (min-width: $screen-lg) {
    width: 33%;
  }
}

.third_xl {
  width: 100%;
  @media (min-width: $screen-xl) {
    width: 33%;
  }
}

.quarter_lg {
  width: 100%;
  @media (min-width: $screen-lg) {
    width: 25%;
  }
}

.two_thirds_xxl {
  width: 100%;
  @media (min-width: $screen-xxl) {
    width: 66%;
  }
}
