.search-filter {
  position: relative;

  &__input {
    padding-left: 30px;

    .side-nav &,
    .qualifier-list &,
    .widgets & {
      width: 100%;
    }
  }
  
  &__icon {
    left: 7px;
    position: absolute;
    top: 7px;
  }
}