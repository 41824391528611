@import 'src/flux-styles/variables';

.tabs {
  border-bottom: 1px solid #D3D8DA;
  margin-top: -#{$medium-spacing};

  &__list {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__list-link {
    color: var(--input-placeholder);
    cursor: pointer;
    display: block;
    font-size: 14px;
    font-weight: 600;
    padding: $medium-spacing $small-spacing;
    position: relative;
    text-decoration: none;

    &:hover {
      color: var(--content-color);
    }

    &--active {
      color: var(--content-color);

      &::after {
        background-color: var(--content-color);
        bottom: 0;
        content: '';
        display: block;
        height: 3px;
        left: 0;
        position: absolute;
        width: 100%;
      }
    }
  }
}