.App {
  text-align: center;
}

.App-logo {
  height: 5vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.table-filter {
  background-color: #fff;
  padding: 15px 10px;
}

.table-filter__heading {
  margin-bottom: 0;
  margin-top: 0;
}

.form--inline .apl-field__label {
  margin-bottom: 0 !important;
  margin-right: 16px;
}

.form--connection-charges-summary input {
  height: 31px;
  line-height: 31px;
  margin-right: 4px;
}
