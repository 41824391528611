// # Components / Alert
//
// Use alerts for short, important messages such as errors and notifications.
// An alert can be a single snippet of text, can have a title or an icon.
// [<span class="badge is-legacy">v0 legacy</span>](#architecture_versioning)
//
// ```example
// <div class="alert">FYI there is a thing</div>
// <div class="alert has-title">
//   <span class="alert-title">Alert</span>
//   <span>Hopefully this message is useful</span>
// </div>
// <div class="alert has-icon">
//  <i class="material-icons is-small is-normal">info</i>
//  So informative!
// </div>
// ```
//
// ## Contextual styles
//
// Naturally, there are many reasons you may want to alert the user.
//
// ```example
// <div class="alert is-success">Done!</div>
// <div class="alert is-warning">Bear this in mind</div>
// <div class="alert is-danger">Failed!</div>
// ```
//
// ## Strong alerts
//
// When you really want to make sure something won't be ignored, use a strong
// alert for extra emphasis.
//
// ```example
// <div class="alert is-strong is-danger">A really bad thing happened</div>
// <div class="alert is-strong is-danger has-icon">
//  <i class="material-icons is-small is-normal">error</i>
//  Another really bad thing happened
// </div>
// ```
@import "variables";

.alert {
  padding: $small-spacing;
  border-radius: $corner-radius;
  background-color: var(--primary-bg);
  color: var(--primary-color-stronger);

  &:not(:last-child) {
    margin-bottom: $small-spacing;
  }

  &.is-success {
    background-color: var(--success-bg);
    color: var(--success-color-stronger);
  }

  &.is-warning {
    background-color: var(--warning-bg);
    color: var(--warning-color-stronger);
  }

  &.is-danger {
    background-color: var(--danger-bg);
    color: var(--danger-color-stronger);
  }

  &.is-strong {
    padding: $medium-spacing;
    background-color: var(--primary-color);
    color: white;
  }

  &.is-strong.is-info {
    background-color: var(--info-color);
  }

  &.is-strong.is-success {
    background-color: var(--success-color);
  }

  &.is-strong.is-warning {
    background-color: var(--warning-color);
  }

  &.is-strong.is-danger {
    background-color: var(--danger-color);
  }

  // With titles

  &.has-title {
    display: flex;
    align-items: center;
    padding: 0;
  }

  .alert-title {
    margin-right: $small-spacing;
    padding: $small-spacing;
    background-color: var(--primary-color);
    border-top-left-radius: $corner-radius;
    border-bottom-left-radius: $corner-radius;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
  }

  &.is-info .alert-title {
    background-color: var(--info-color);
  }

  &.is-success .alert-title {
    background-color: var(--success-color);
  }

  &.is-warning .alert-title {
    background-color: var(--warning-color);
  }

  &.is-danger .alert-title {
    background-color: var(--danger-color);
  }

  &.is-strong .alert-title {
    margin-right: $medium-spacing;
    padding: $medium-spacing;
    background-color: rgba(0, 0, 0, 0.2);
  }

  // With icons

  &.has-icon {
    display: flex;
    align-items: center;

    i:first-child {
      margin-right: $small-spacing;
      color: var(--primary-color);
      align-self: flex-start;
    }

    &.is-info i:first-child {
      color: var(--info-color);
    }

    &.is-success i:first-child {
      color: var(--success-color);
    }

    &.is-warning i:first-child {
      color: var(--warning-color);
    }

    &.is-danger i:first-child {
      color: var(--danger-color);
    }

    &.is-strong i:first-child {
      margin-right: $medium-spacing;
      color: white;
    }
  }
}

ol.alert {
  list-style-type: decimal;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 30px;
}

ul.alert {
  list-style: none;
  padding-top: 5px;
  padding-bottom: 5px;
}
