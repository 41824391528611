@import "src/flux-styles/colors";
@import "src/flux-styles/variables";

.page {
  color: var(--content-color);
  display: flex;
  flex-direction: column;
  font-family: $sans-serif;
  font-size: $font-size;
  height: 95%;
  line-height: $line-height;
  margin: 2.5%;
  width: 95%;

  h1 {
    margin-top: 0;
  }

  &__heading {
    margin-bottom: 1em;
  }

  &__title {
    color: var(--content-color);

    // to overwrite apl h1 styles
    .page-header & {
      font-size: 1.0575rem;
      font-weight: 600;
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  &__title-link {
    color: var(--content-color);
    text-decoration: none;

    &:hover {
      color: var(--primary-color);
      text-decoration: underline;
    }
  }
}

.page-header {
  background-color: var(--base-color);
  border-bottom: 1px solid #c4c4c4;
}