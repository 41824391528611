.loading-spinner {
  color: #000;
  display: inline-block;
  height: 16px;
  position: relative;
  width: 16px;

  div {
    animation: loading-spinner 1.2s linear infinite;
    transform-origin: 8px 8px;

    &::after {
      background: #000;
      border-radius: 20%;
      content: '';
      display: block;
      height: 1.5px;
      left: 12px;
      position: absolute;
      top: 7px;
      width: 4px;
    }

    &:nth-child(1) {
      animation-delay: -1.1s;
      transform: rotate(0deg);
    }

    &:nth-child(2) {
      animation-delay: -1s;
      transform: rotate(30deg);
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
      transform: rotate(60deg);
    }

    &:nth-child(4) {
      animation-delay: -0.8s;
      transform: rotate(90deg);
    }

    &:nth-child(5) {
      animation-delay: -0.7s;
      transform: rotate(120deg);
    }

    &:nth-child(6) {
      animation-delay: -0.6s;
      transform: rotate(150deg);
    }

    &:nth-child(7) {
      animation-delay: -0.5s;
      transform: rotate(180deg);
    }

    &:nth-child(8) {
      animation-delay: -0.4s;
      transform: rotate(210deg);
    }

    &:nth-child(9) {
      animation-delay: -0.3s;
      transform: rotate(240deg);
    }

    &:nth-child(10) {
      animation-delay: -0.2s;
      transform: rotate(270deg);
    }

    &:nth-child(11) {
      animation-delay: -0.1s;
      transform: rotate(300deg);
    }

    &:nth-child(12) {
      animation-delay: 0s;
      transform: rotate(330deg);
    }
  }
}



@keyframes loading-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}