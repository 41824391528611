.plan-link {
  margin-right: 20px;

  &__link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__info {
    font-size: 14px;
    line-height: 1.5;
    margin: 0;
  }

  &__state {
    position: relative;

    &--published::before {
      background-color: var(--success-color);
    }

    &--draft::before {
      border: 2px solid var(--warning-color);
    }

    &--deprecated::before {
      border: 2px solid var(--secondary-color);
    }

    &--deprecated::after {
      background-color: var(--secondary-color);
      content: '';
      display: block;
      height: 8px;
      left: 4px;
      position: absolute;
      transform: rotate(45deg);
      width: 2px;
    }
  }

  &__state::before {
    border-radius: 50%;
    content: '';
    display: block;
    height: 10px;
    margin-right: 6px;
    width: 10px;
  }
}