@import 'src/flux-styles/variables';

.widgets {
  &__column {
    flex: 1 0 auto;
    max-width: calc(100% / 3);
  }
}

.widget {
  background-color: #fff;
  border-radius: 2.5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  margin: $small-spacing;

  &--full {
    width: auto;
  }

  &--stacked-bottom {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: 0;
  }

  &--stacked-middle {
    border-radius: 0;
    margin-bottom: 0;
    margin-top: 0;
  }

  &--stacked-top {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0;
  }

  &__title {
    border-bottom: 1px solid #D3D8DA;
    line-height: 18px;
    margin-bottom: 0;
    padding: $big-spacing $medium-spacing;

    &--inline {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__content {
    padding: $big-spacing $medium-spacing;
  }

  &__list {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

  &__list-item {
    align-items: center;
    border-bottom: 1px solid var(--secondary-bg);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    padding: $small-spacing $medium-spacing;
    position: relative;
  }

  &__list-link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__table {
    border-collapse: collapse;
    width: 100%;
  }

  &__table-cell,
  &__table-header {
    border-bottom: 1px solid var(--secondary-bg);
    padding: 10px 0;

    &:first-child {
      padding-left: $medium-spacing;
    }

    &:last-child {
      padding-right: $medium-spacing;
    }
  }

  &__table-cell {
    font-weight: 400;
  }

  &__table-header {
    color: #757575;
    font-weight: 600;
  }
}

.uml-filter {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 10px;
  height: 100%;
}