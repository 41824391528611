@import "src/flux-styles/colors";
@import "src/flux-styles/variables";
@import "src/flux-styles/card";

.card {
  background: var(--base-color);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 2.5px;

  &--square-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    .apl-table-v1 {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &__inner {
    margin-left: -#{$medium-spacing};
    margin-right: -#{$medium-spacing};
  }

  &__column-gap {
    column-gap: 20px;
  }
}
