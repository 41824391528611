h1 {
  &.not-found-heading {
    font-size: 32px;
  }
}

.not-found-page {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}


