@import "src/flux-styles/colors";
@import "src/flux-styles/variables";

.splash-screen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.splash-screen .loading-dot {
  font-size: 100px;
  position: relative;
  animation: ease-in-out infinite alternate;
  animation-name: run;
  animation-duration: 2s;
}

@keyframes run {
  0% {
    left: -25%;
    color: var(--secondary-bg);
  }
  50% {
    color: var(--brand-color);
  }
  100% {
    left: 25%;
    color: var(--secondary-bg);
  }
}
