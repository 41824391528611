@import "mixins/theming";
@import "mixins/svg";

// # Guides / Theming
//
// There are plenty of themed colours for you to add sparkle to your pages.
// The `strong` and `stronger` variants are a handy way for you to modify
// colours based on state, at least until the `color()` CSS function is
// supported. For example, buttons use the `strong` colour variant when
// hovered and the `stronger` variant when pressed.
//
// Also check out the `themed-svg` mixin for a handy way to have themed
// background shapes and images.
//
// ### Primary
// <p class="pattern-swatches">
//   <span class="pattern-swatch" data-var="primary-color"></span>
//   <span class="pattern-swatch" data-var="primary-color-strong"></span>
//   <span class="pattern-swatch" data-var="primary-color-stronger"></span>
//   <span class="pattern-swatch" data-var="primary-bg"></span>
//   <span class="pattern-swatch" data-var="primary-bg-strong"></span>
//   <span class="pattern-swatch" data-var="primary-bg-stronger"></span>
// </p>
//
// ### Secondary
// <p class="pattern-swatches">
//   <span class="pattern-swatch" data-var="secondary-color"></span>
//   <span class="pattern-swatch" data-var="secondary-color-strong"></span>
//   <span class="pattern-swatch" data-var="secondary-color-stronger"></span>
//   <span class="pattern-swatch" data-var="secondary-bg"></span>
//   <span class="pattern-swatch" data-var="secondary-bg-strong"></span>
//   <span class="pattern-swatch" data-var="secondary-bg-stronger"></span>
// </p>
//
// ### Info
// <p class="pattern-swatches">
//   <span class="pattern-swatch" data-var="info-color"></span>
//   <span class="pattern-swatch" data-var="info-color-strong"></span>
//   <span class="pattern-swatch" data-var="info-color-stronger"></span>
//   <span class="pattern-swatch" data-var="info-bg"></span>
//   <span class="pattern-swatch" data-var="info-bg-strong"></span>
//   <span class="pattern-swatch" data-var="info-bg-stronger"></span>
// </p>
//
// ### Success
// <p class="pattern-swatches">
//   <span class="pattern-swatch" data-var="success-color"></span>
//   <span class="pattern-swatch" data-var="success-color-strong"></span>
//   <span class="pattern-swatch" data-var="success-color-stronger"></span>
//   <span class="pattern-swatch" data-var="success-bg"></span>
//   <span class="pattern-swatch" data-var="success-bg-strong"></span>
//   <span class="pattern-swatch" data-var="success-bg-stronger"></span>
// </p>
//
// ### Warning
// <p class="pattern-swatches">
//   <span class="pattern-swatch" data-var="warning-color"></span>
//   <span class="pattern-swatch" data-var="warning-color-strong"></span>
//   <span class="pattern-swatch" data-var="warning-color-stronger"></span>
//   <span class="pattern-swatch" data-var="warning-bg"></span>
//   <span class="pattern-swatch" data-var="warning-bg-strong"></span>
//   <span class="pattern-swatch" data-var="warning-bg-stronger"></span>
// </p>
//
// ### Danger
// <p class="pattern-swatches">
//   <span class="pattern-swatch" data-var="danger-color"></span>
//   <span class="pattern-swatch" data-var="danger-color-strong"></span>
//   <span class="pattern-swatch" data-var="danger-color-stronger"></span>
//   <span class="pattern-swatch" data-var="danger-bg"></span>
//   <span class="pattern-swatch" data-var="danger-bg-strong"></span>
//   <span class="pattern-swatch" data-var="danger-bg-stronger"></span>
// </p>
//
// ### Misc
// <p class="pattern-swatches">
//   <span class="pattern-swatch" data-var="base-color"></span>
//   <span class="pattern-swatch" data-var="content-color"></span>
//   <span class="pattern-swatch" data-var="brand-color"></span>
//   <span class="pattern-swatch" data-var="muted-color"></span>
//   <span class="pattern-swatch" data-var="highlight-color"></span>
// </p>
//
// ### Domain-specific
// <p class="pattern-swatches">
//   <span class="pattern-swatch" data-var="account-residential-color"></span>
//   <span class="pattern-swatch" data-var="account-business-color"></span>
//   <span class="pattern-swatch" data-var="energy-electricity-color"></span>
//   <span class="pattern-swatch" data-var="energy-gas-color"></span>
// </p>
@include themed-colors(
  (
    light: (
      primary-color: #0e91cf,
      primary-color-strong: darken(#0e91cf, 7%),
      primary-color-stronger: darken(#0e91cf, 14%),
      primary-bg: #c7ebfb,
      primary-bg-strong: darken(#c7ebfb, 7%),
      primary-bg-stronger: darken(#c7ebfb, 14%),
      secondary-color: #546267,
      secondary-color-strong: darken(#546267, 7%),
      secondary-color-stronger: darken(#546267, 14%),
      secondary-bg: #e3e8ea,
      secondary-bg-strong: darken(#e3e8ea, 7%),
      secondary-bg-stronger: darken(#e3e8ea, 14%),
      info-color: #0e91cf,
      info-color-strong: darken(#0e91cf, 7%),
      info-color-stronger: darken(#0e91cf, 14%),
      info-bg: #c7ebfb,
      info-bg-strong: darken(#c7ebfb, 7%),
      info-bg-stronger: darken(#c7ebfb, 14%),
      success-color: #7dab33,
      success-color-strong: darken(#7dab33, 7%),
      success-color-stronger: darken(#7dab33, 14%),
      success-bg: #cce2a7,
      success-bg-strong: darken(#cce2a7, 7%),
      success-bg-stronger: darken(#cce2a7, 14%),
      warning-color: #e59120,
      warning-color-strong: darken(#e59120, 7%),
      warning-color-stronger: darken(#e59120, 14%),
      warning-bg: #fde898,
      warning-bg-strong: darken(#fde898, 7%),
      warning-bg-stronger: darken(#fde898, 14%),
      danger-color: #ef1f38,
      danger-color-strong: darken(#ef1f38, 7%),
      danger-color-stronger: darken(#ef1f38, 14%),
      danger-bg: #fbc6cc,
      danger-bg-strong: darken(#fbc6cc, 7%),
      danger-bg-stronger: darken(#fbc6cc, 14%),
      base-color: white,
      content-color: #333,
      muted-color: #6b7377,
      highlight-color: white,
      tint-bg: #f5f7f9,
      visited-link-color: adjust-hue(darken(#0e91cf, 14%), 25deg),
      brand-color: #240247,
      brand-color-strong: saturate(darken(#240247, 13%), 25%),
      brand-color-stronger: saturate(darken(#240247, 22%), 50%),
      brand-color-light: desaturate(lighten(#240247, 14%), 10%),
      brand-color-lighter: desaturate(lighten(#240247, 18%), 15%),
      account-residential-color: #5f259f,
      account-business-color: #0288d1,
      energy-electricity-color: #ff9d00,
      energy-gas-color: #0288d1,
      input-placeholder: #757575,
    ),
    dark: (
      primary-color: #0e91cf,
      primary-color-strong: lighten(#0e91cf, 7%),
      primary-color-stronger: lighten(#0e91cf, 14%),
      primary-bg: #042d40,
      primary-bg-strong: lighten(#042d40, 7%),
      primary-bg-stronger: lighten(#042d40, 14%),
      secondary-color: lighten(#546267, 17%),
      secondary-color-strong: lighten(#546267, 24%),
      secondary-color-stronger: lighten(#546267, 31%),
      secondary-bg: #3b464a,
      secondary-bg-strong: lighten(#3b464a, 7%),
      secondary-bg-stronger: lighten(#3b464a, 14%),
      info-color: #0e91cf,
      info-color-strong: lighten(#0e91cf, 7%),
      info-color-stronger: lighten(#0e91cf, 14%),
      info-bg: #042d40,
      info-bg-strong: lighten(#042d40, 7%),
      info-bg-stronger: lighten(#042d40, 14%),
      success-color: #83b336,
      success-color-strong: lighten(#83b336, 7%),
      success-color-stronger: lighten(#83b336, 14%),
      success-bg: #3b5016,
      success-bg-strong: lighten(#3b5016, 7%),
      success-bg-stronger: lighten(#3b5016, 14%),
      warning-color: #e59120,
      warning-color-strong: lighten(#e59120, 7%),
      warning-color-stronger: lighten(#e59120, 14%),
      warning-bg: #6a420c,
      warning-bg-strong: lighten(#6a420c, 7%),
      warning-bg-stronger: lighten(#6a420c, 14%),
      danger-color: #ef1f38,
      danger-color-strong: lighten(#ef1f38, 10%),
      danger-color-stronger: lighten(#ef1f38, 17%),
      danger-bg: #550610,
      danger-bg-strong: lighten(#550610, 7%),
      danger-bg-stronger: lighten(#550610, 14%),
      base-color: #273033,
      content-color: #ddd,
      brand-color: #941854,
      muted-color: #839298,
      highlight-color: white,
      tint-bg: #30393c,
      visited-link-color: adjust-hue(lighten(#0e91cf, 14%), 25deg),
    ),
  )
);
