@import "src/flux-styles/colors";

.button {
  &--link {
    background: none;
    border: none;
    color: var(--primary-color);
    cursor: pointer;
    padding: 0;
    text-decoration: none;

    &:visited {
      color: var(--primary-color);
    }

    &:active {
      color: var(--primary-color-strong);
    }

    &:hover {
      text-decoration: underline;
    }

    &[disabled] {
      background: none;
      text-decoration: none;
    }
  }
}