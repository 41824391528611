.table--scrolling {
  display: block;
  overflow-x: scroll;

  .sticky-col {
    left: 0;
    min-width: 150px;
    position: -webkit-sticky;
    position: sticky;
    top: auto;
    z-index: 2;

    &::after {
      background: linear-gradient(90deg, #D4D8DA 0%, rgba(212, 216, 218, 0.03) 100%);
      content: '';
      display: block;
      height: 100%;
      left: 100%;
      position: absolute;
      top: 0;
      width: 5px;
    }

    &--no-shadow {
      &::after {
        display: none;
      }
    }
  }
}

.table--nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

.zebra-table {
  &__row:nth-child(even) {
    // had to re-add this style because the default apl-table-v1 styles caused
    // a filter effect on hover which messed with the context menu styles
    td {
      background-color: #F7F7F7;
    }
  }
}