/* Paginator styling */

.pagination{
    display: inline-block;
  }
  
  .pagination ul li {
    float: left;
    list-style: none;
    text-align: center;
    margin-right: 2px;
  }
  
  .pagination ul li a {
    border: #cecdcd solid .8px;
    color: rgb(1, 37, 71);
    float: left;
    padding: 4px 16px;
    text-decoration: none;
  }
  
  .active-page-number {
    background-color: #252926;
    border-color: #2f3530;
    border-radius: 2px;
  }
  
  .active-page-number a {
    color: #fff!important;
  }
  
  .pagination li:hover {
    background-color:  #a4a8a5;
    color: white;
    border-radius: 2px;
  }