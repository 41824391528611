// Typography

$sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
  Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$monospace: Monaco, Consolas, monospace;

$font-size: 13px;
$font-size-small: 12px;
$font-size-tiny: 10px;
$line-height: 1.4;

// Sizes

$minimum-spacing: 5px;
$small-spacing: 10px;
$medium-spacing: 15px;
$big-spacing: 20px;

$corner-radius: 5px;

// Layout

$header-height: 50px;
$header-menu-toggle-width: 80px;
$header-logo-width: 220px;
$header-z-index: 2000;

// Components

$input-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);

// custom colours

$menu-button-color: #4A2566;