@import "src/flux-styles/colors";
@import "src/flux-styles/variables";

.side-nav {
  background-color: var(--base-color);
  bottom: 0;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  left: -290px;
  position: absolute;
  top: 50px;
  transition: left 0.125s ease-in;
  width: 290px;
  z-index: 5000;

  &--active {
    left: 0;
  }

  &__top {
    border-bottom: 1px solid #D3D8DA;
    padding: 10px;
  }

  &__nav {
    margin-top: 5px;
  }

  &__link {
    color: var(--content-color);
    display: block;
    font-size: 15px;
    line-height: 1.33;
    padding: 10px 20px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &--active {
      border-left: 3px solid $menu-button-color;
      padding-left: 17px;
    }
  }

  &__bottom {
    border-top: 1px solid #D3D8DA;
    padding: 5px 0;
  }
}

.side-nav-overlay {
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  top: 50px;
  right: 0;
  z-index: 998;

  &--active {
    display: block;
  }
}