.billing-status-field__container {
  position: relative;

  &--active,
  &--on-hold {
    &::before {
      border-radius: 50%;
      content: '';
      display: block;
      height: 10px;
      left: 8px;
      margin-top: -5px;
      position: absolute;
      top: 50%;
      width: 10px;
      z-index: 2;
    }
  }

  &--active::before {
    background-color: #2C8F65;
  }

  &--on-hold::before {
    background-color: #DB0F28;
  }
}

.invoice-format-field__container {
  position: relative;
  margin-right: 30px;
  margin-bottom: 0;
}
