@import "src/flux-styles/colors";
@import "src/flux-styles/variables";

.header {
  align-items: center;
  background-color: var(--brand-color);
  display: flex;
  flex-direction: row;
  height: $header-height;

  &__left {
    display: flex;
    flex-grow: 4;
    height: 100%;
    justify-content: flex-start;
  }

  &__menu-button {
    align-items: center;
    background-color: $menu-button-color;
    color: var(--base-color);
    cursor: pointer;
    display: flex;
    font-size: $font-size;
    font-weight: 600;
    height: 100%;
    padding: 0 1.7em;
    text-transform: uppercase;

    &:hover {
      background-color: lighten($menu-button-color, 5%);
    }
  }

  &__center {
    display: flex;
    flex-grow: 1;
    height: 100%;
    justify-content: center;
  }

  &__right {
    display: flex;
    flex-grow: 4;
    height: 100%;
    justify-content: flex-end;
  }

  &__date-time {
    align-items: center;
    color: var(--base-color);
    display: flex;
    font-size: $font-size;
    padding: 0 1em;
  }
}
