.adv-pagination {
  &__button {
    padding: 4px;

    &[disabled] img {
      opacity: 0.5;
    }
  }

  &__button:disabled {
    cursor: not-allowed;
    
    img {
      opacity: 0.2;
    }
  }
}