@import "src/flux-styles/colors";

.button_as_link {
  color: var(--primary-color);
  &:visited {
    color: var(--primary-color);
  }
  &:active {
    color: var(--primary-color-strong);
  }
}
