// # Components / Badge
//
// A small highlighted snippet. Useful for counting things.
// [<span class="badge is-legacy">v0 legacy</span>](#architecture_versioning)
//
// ```example
// <span class="badge">14</span>
// <span class="badge is-info">1 interesting</span>
// <span class="badge is-success">3 good</span>
// <span class="badge is-warning">4 not good</span>
// <span class="badge is-danger">2 bad</span>
// ```
@import "variables";
@import "colors";

.badge {
  --color: var(--secondary-color-strong);
  --bg: var(--secondary-bg-strong);

  display: inline-block;
  min-width: 1em;
  padding: 0.3em 0.5em;
  margin: -0.3em 0;
  font-size: 87.5%;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  color: var(--color);
  background-color: var(--bg);
  border-radius: calc($corner-radius / 2);

  &.is-info {
    --color: var(--info-color-stronger);
    --bg: var(--info-bg-strong);
  }

  &.is-success {
    --color: var(--success-color-stronger);
    --bg: var(--success-bg-strong);
  }

  &.is-warning {
    --color: var(--warning-color-stronger);
    --bg: var(--warning-bg-strong);
  }

  &.is-danger {
    --color: var(--danger-color-stronger);
    --bg: var(--danger-bg-strong);
  }

  // ## With titles
  //
  // You can add a title to a badge using the `data-title` attribute. This title
  // will be displayed in bold next to the badge text.
  //
  // ```example
  // <span class="badge" data-title="Total">14</span>
  // ```
  &[data-title]:before {
    content: attr(data-title) " – ";
    // display: inline-block;
    // padding-right: 0.4em;
    // border-right: 1px solid var(--color);
    // margin-right: 0.4em;
    font-weight: bold;
  }
}

// ## In headings
//
// Badges can be used in headings to show the state of an object. Also see the
// [header](#components_header) docs.
//
// ```example
// <h1>Gain Switch <span class="badge is-danger" data-title="Cancelled">Withdrawn by us</span></h1>
// <h2>Discovery Requests <span class="badge is-warning">1 Pending</span></h2>
// ```
h1,
h2 {
  .badge {
    position: relative;
    bottom: 0.05em;
    font-size: 65%;
  }
}
